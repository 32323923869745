/* Login Page Styles */

/* Fullscreen Container */
// .container-fluid {
//     height: 100vh;
//     background-color: #f5f7fa;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 0;
//   }
  
//   .row {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//   }
  
  /* Outer Login Container */
  .login-outer {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  /* Left Panel */
  .login-left-panel {
    flex: 1;
    background: linear-gradient(139.67deg, #5c7ec9 4.32%, #2759ab 99.29%);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    position: relative;
  }
  
  .logo-img {
    margin-bottom: 4rem;
  }
  
  .logo-img img {
    width: 170px; /* Increased logo size */
  }
  
  .login-txt {
    margin-top: 10px;
  }
  
  .login_with_email{
    font-size: 17px;
    display: flex;
    gap: 10px;
  }

  .login-txt h1:first-child {
    font-size: 2.5rem;
    font-weight: 400;
    margin: 0;
    line-height: 1.2;
    color: #ffffff;
  }
  
  .login-txt h1:last-child {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.2;
    color: #ffffff;
  }
  
  .login-bg-img {
    position: absolute;
    bottom: 110px; 
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 550px;
  }
  
  /* Right Panel */
  .login-right-panel {
    flex: 1;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;
    margin-left: 8rem;

  }
  
  .login-heading {
    text-align: left; /* Align login text to the left */
    margin-bottom: 2rem;
  }
  
  .login-heading h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333333;
  }
  
  /* Login Tab Styling */
  .login-tab {
    width: 100%;
    max-width: 350px;
    text-align: left; /* Align tab content to the left */
  }
  
  .tab-pane {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align Google button to the left */
  }
  
  /* Google Login Button */
  .google-login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 0.8rem 1.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .google-login-btn:hover {
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .login-outer {
      flex-direction: column;
      height: auto;
    }
  
    .login-left-panel {
      padding: 2rem;
      text-align: center;
    }
  
    .login-bg-img {
      position: relative;
      margin: 2rem auto 0;
    }
  
    .login-right-panel {
      padding: 2rem;
      text-align: center;
    }
  
    .login-heading {
      text-align: center;
    }
  
    .login-tab {
      text-align: center;
    }
  
    .tab-pane {
      align-items: center;
    }
  }

  
  