.top-heading {
    padding: 18px 0;
    display: block;
    h1 {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: $heading-color;
    }
}


.loan-filter-bg {
    padding: 0px 0;
    .top-filter-bg {
        background: $white;
        // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 20px;
    }
    .lead-list-filter {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .flex-column {
        .tab-list {
            display: flex;
            align-items: center;
            padding: 25px 20px 18px;
            background: $white;
            border-radius: 8px 8px 0 0;
            &:after {
                display: none;
            }
        }
    }
    .css-1pcexqc-container {
        input {
            appearance: auto;
            -webkit-appearance: auto;
            -moz-appearance: auto;
        }
    }
    fieldset {
        width: 100%;
        margin-right: 8px;
    }
    .search-bx {
        min-width: 250px;
    }
}

.filter-filed-form {
    width: calc(60% - 260px);
    display: flex;
    align-items: center;

    .search-bx {
        input {
            height: 42px !important;
        }
        &.finance-search {
            input {
                height: 48px !important;
            }  
        }
    }
}

.btn-primary{
background-color: #0046b3;
}  

.btn-reset {
    background: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    border: 0px;
    margin-left: 10px;
}

.search-bx {
    .material {
        .form-input {
            padding-left: 45px;
        }
        i {
            position: absolute;
            left: 15px;
            font-size: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.btn-add-enquiry {
    background-color: #0046b3;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;

    .plus-icon {
      font-size: 18px;
      font-weight: bold;
    }

    &:hover {
      background-color: #0046b3;
    }
  }

/* Tabs */
.lead-list-tabs {
    margin-top: 20px;
    margin-bottom: 20px;
    .nav-pills {
      display: flex;
      gap: 10px;
  
      .nav-link {
        background-color: #e9ecef;
        border-radius: 30px;
        color: #495057;
        padding: 8px 16px;
  
        &.active {
          background-color: #0046b3;
          color: #fff;
        }
      }
    }
  }


.action-btn button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    background-color: #ffffff; 
    color: #007bff; 
    border: 1px solid #007bff; 
    border-radius: 4px; 
    padding: 8px 16px; 
    font-size: 14px; 
    font-weight: 500; 
    cursor: pointer;
    transition: all 0.3s ease-in-out; 
    text-transform: capitalize; 
  }
  
  .status-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
  
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }
  
    &.success::before {
      background-color: #32c8a2; // Green
    }
  
    &.failure::before {
      background-color: #e74c3c; 
    }
  
    &.unknown::before {
      background-color: #ccc; // Gray for unknown
    }
  
    .tooltip {
      position: relative;
  
      .tooltip-icon {
        font-size: 12px;
        margin-left: 5px;
        cursor: pointer;
      }
  
      &:hover .tooltip-text {
        display: block;
      }
  
      .tooltip-text {
        display: none;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #000;
        color: #fff;
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 4px;
        white-space: nowrap;
        z-index: 10;
      }
    }
  }

  .short-note{
  margin :10px
   } 
  

