
.single-select, .multiselect-dropDown {
    .react-select__placeholder {
        font-size: 11px;
    }
  
    .react-select__indicators {
        svg {
            display: none;
        }
    }
  
    .react-select__indicator-separator,
    .css-109onse-indicatorSeparator {
        display: none;
    }
  
    .react-select__control--menu-is-open,
    .react-select__control {
        height: 42px;
        border-radius: 8px !important;
        outline: none !important;
        box-shadow: none;
        border-color: #bbbbbb !important;
  
        &:hover {
            border-color: #bbbbbb !important;
            box-shadow: none;
            border-radius: 8px;
            outline: none !important;
        }
    }
  
    .react-select__indicators {
        &:after {
            content: "\e917";
            position: absolute;
            right: 3px;
            color: #000;
            font-family: dc !important;
            font-size: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
  
    .react-select__single-value,
    .css-1v99tuv {
        font-size: 12px;
    }
    .css-1nmdiq5-menu {
        z-index: 20 !important;
    }
    .react-select__menu-list,
    .css-11unzgr {
        font-size: 12px;
    }
  
    .css-1qprcsu-option,
    .css-1vr111p-option {
        font-weight: normal;
  
        input {
            position: relative;
            top: 3px;
        }
    }
  
    .react-select__single-value {
        max-width: 100%!important;
    }

    .react-select__value-container--has-value {
        .react-select__placeholder {
            background: #fff;
            transition: all 0.15s ease-in-out;
            margin-left: -2px;
            font-size: 12px;
            padding: 0 3px;
            width: auto;
            position: absolute;
            top: -5px;
        }
    }

    .react-select__control--menu-is-open,
    .react-select__control--is-focused {
        .react-select__placeholder {
            background: #fff;
            transition: all 0.15s ease-in-out;
            margin-left: -2px;
            font-size: 12px;
            padding: 0 3px;
            width: auto;
            position: absolute;
            top: -5px;
        }
    }

    .react-select__control--is-disabled {
        min-height: 42px;
    }
}

.report-filter{
    .single-select{
        .react-select__value-container--has-value {
            .react-select__placeholder {
                top: -19px;
            }
        }
    
        .react-select__control--menu-is-open,
        .react-select__control--is-focused {
            .react-select__placeholder {
                top: -19px;
            }
        }
    }

}

.branches-select {
    .multiselect-dropDown {
        .react-select__menu-list {
            max-height: 150px !important;
        }
    }
}

.clearable {
    .react-select__clear-indicator svg {
        display: block !important; 
    }
}

.multiselect-dropDown .react-select{
    font-size: 12px;
    color: #000;
}

.template-filter-select-box {
    .single-select {
        .react-select__value-container--has-value {
            .react-select__placeholder {
                top: -16px !important;
            }
        }
        
        .react-select__control--menu-is-open,
        .react-select__control--is-focused {
            .react-select__placeholder {
                top: -16px !important;
            }
        }
    }
}


.selectbox-placeholder-remover {
    .react-select__control--menu-is-open,
    .react-select__control--is-focused {
        .react-select__placeholder {
            display: none !important;
        }
    }
}

.selectbox-placeholder-ellipsis {
    .react-select__placeholder {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}