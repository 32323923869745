.contact-container {
    width: 460px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 15px;

    .modal-header {
        margin: 10px 0;
      }
      
      .modal-header h2 {
        font-size: 18px;
        font-weight: 500;
      }

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 10px 0;
        padding: 0;
      }
      
      .modal-input {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
      }
    
      .form-filed {
        display: flex;
        align-items: center;
        margin: 0; 
        padding: 0; 
    }
      
      .form-filed:first-of-type .form-input {
        width: 110px;
      }
      .error-msg-txt {
        color: red;
        font-size: 10px;
        margin-top: 4px;
        width: 100px;
      }
      
      .form-filed:last-of-type .form-input  {
        flex: 1; 
        flex-grow: 1;
      }
      
      .form-control {
        height: 40px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 0 12px;
        font-size: 14px;
        outline: none;
        transition: border-color 0.3s;
      }
      
      .form-control:focus {
        border-color: #0046b3;
      }

      .btn-edit-continue {
        display: flex;
        // justify-content: space-between; 
        gap: 12px; 
        margin: 0 auto; 
        padding: 0;
        width: 100%; 
        box-sizing: border-box;
    
    }
    
    .btn-edit {
        // flex: 1;
        width: 40%;
        height: 50px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        border: 2px solid #0046b3;
        border-radius: 8px;
        cursor: pointer;
        color: #0046b3;
        background-color: #ffffff; 
        padding: 8px 0;
    }
    
    .btn-continue {
        width: 40%;
        height: 50px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        background-color: #0046b3; 
        color: #ffffff; 
        padding: 8px 0; 
        transition: background-color 0.3s;
    }

    .error-message{
      color: red;
      font-size: 10px; 
      font-weight: 500;
      // margin-top: 4px; 
    }
      
  }
  

  
 