
.sticky-nav {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #dddddd;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    position: relative;
  
    .logo {
      img {
        height: 40px;
        width: auto;
      }
    }
  
    .user-info {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #000; /* Black text */
  
      .user-initial {
        background: linear-gradient(135deg, #00b3a6, #007bff);
        color: #ffffff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin-right: 10px;
      }
  
      .user-details {
        display: flex;
        flex-direction: column;
  
        strong {
          font-size: 14px;
          margin-bottom: 2px;
        }
  
        span {
          font-size: 12px;
          color: gray;
        }
      }
    }
  }
  
  .application-list {
    padding: 20px;
  
    h1 {
      font-size: 24px;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 16px;
      color: #555555;
    }
  }


  .logout-container {
    position: absolute;
    top: 100%; 
    right: 0; 
    background-color: #fff; 
    color: #000; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 100;
    padding: 10px;
    width: 100px; /* Adjust width as needed */
  }
  
  .logout-button {
    background: none;
    border: none;
    color: #000; /* Black text */
    cursor: pointer;
    font-size: 14px;
    text-align: left;
    padding: 5px 10px;
    width: 100%;
  }
  
  .logout-button:hover {
    background-color: #f5f5f5; /* Light gray hover effect */
    border-radius: 4px;
  }